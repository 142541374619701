import { render, staticRenderFns } from "./activeManager.vue?vue&type=template&id=6507fde0&scoped=true&"
import script from "./activeManager.vue?vue&type=script&lang=js&"
export * from "./activeManager.vue?vue&type=script&lang=js&"
import style0 from "./activeManager.vue?vue&type=style&index=0&id=6507fde0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6507fde0",
  null
  
)

export default component.exports